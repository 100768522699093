import { AppInfo } from './App';

export function isIOS() {
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  );
}
export function isWindows() {
  return (
    // User agent with Windows terms (case-insensitive)
    /Win/i.test(navigator.userAgent) ||
    // Platform check for Windows
    navigator.platform.toLowerCase() === "win32" 
  );
}
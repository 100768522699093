import React, { useEffect, useMemo, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import type { MenuProps } from 'antd';
import { Dropdown, Button, Menu, Affix, Input, Space, Typography } from 'antd';
import AppList from './lists/AppList';
import { AndroidFilled, AppleFilled, WindowsFilled } from '@ant-design/icons';
import { isIOS, isWindows } from './utils';
import { createClient } from '@supabase/supabase-js';
import { Database } from './database.types';

const { Search } = Input;

export type AppInfo = Database['public']['Tables']['app']['Row'];

const items: MenuProps['items'] = [
  {
    label: 'iOS',
    key: 'iOS',
    icon: <AppleFilled />
  },
  {
    label: 'Android',
    key: 'Android',
    icon: <AndroidFilled />
  },
  {
    label: 'Windows',
    key: 'Windows',
    icon: <WindowsFilled />
  }
];

function App() {
  // ptisthebest!
  const supabase = createClient<Database>(
    'https://rctzulkrzgwfqmmezllp.supabase.co',
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJjdHp1bGtyemd3ZnFtbWV6bGxwIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTI3NjE2MTMsImV4cCI6MjAwODMzNzYxM30.h8qkj5BGY4BQk2LUw0UzBU9xZPURTjjDwBj8Nw7OONg'
  );

  const [filter, setFilter] = useState('');
  const [fileContent, setFileContent] = useState<AppInfo[]>([]);
  console.log(process.env.REACT_APP_TIMESTAMP);
  useEffect(() => {
    const fetchData = async () => {
      const { data: iosData, error: iosError } = await supabase
        .from(process.env.REACT_APP_TABLE_NAME != null ? process.env.REACT_APP_TABLE_NAME : 'app')
        .select()
        .filter('platform', 'like', '%ios%')
        .order('runNumber', { ascending: false })
        .limit(100);
      const { data: androidData, error: androidError } = await supabase
        .from(process.env.REACT_APP_TABLE_NAME != null ? process.env.REACT_APP_TABLE_NAME : 'app')
        .select()
        .filter('platform', 'like', '%android%')
        .order('runNumber', { ascending: false })
        .limit(100);
      const { data: windowsData, error: windowsError } = await supabase
        .from(process.env.REACT_APP_TABLE_NAME != null ? process.env.REACT_APP_TABLE_NAME : 'app')
        .select()
        .filter('platform', 'like', '%windows%')
        .order('runNumber', { ascending: false })
        .limit(100);
      const allData = [
        ...(iosData ?? []),
        ...(androidData ?? []),
        ...(windowsData ?? [])
      ];
      // console.log(iosData);
      setFileContent(allData ?? []);
    };

    fetchData();
  }, []);

  let initialTab = 'Android';
  if (isWindows()) {
    initialTab = 'Windows';
  }
  if (isIOS()) {
    initialTab = 'iOS';
  }
  const [tab, setTab] = useState(initialTab);
  const filteredContent = useMemo(
    () =>
      fileContent.filter(
        (e) =>
          e.branch.includes(filter) ||
          e.build_type.includes(filter) ||
          e.version.includes(filter) ||
          e.build_number.includes(filter) ||
          e.runNumber.includes(filter)
      ),
    [filter, fileContent]
  );

  return (
    <div>
      <Space
        direction="vertical"
        size={'large'}
        style={{ display: 'flex', padding: '0px 24px' }}
      >
        <Affix>
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Menu
              onClick={(e) => setTab(e.key)}
              selectedKeys={[tab]}
              mode="horizontal"
              items={items}
            />
            <Search
              placeholder="Search for app here"
              enterButton
              onChange={(e) => setFilter(e.currentTarget.value)}
            />
            <Typography.Link href="https://olacommunity.slack.com/archives/C05GLAEB347/p1692772142866939?thread_ts=1692771901.748489&cid=C05GLAEB347">
              Click here for password
            </Typography.Link>
          </Space>
        </Affix>
        {tab == 'Android' && (
          <AppList
            downloadInfo={filteredContent.filter(
              (e) => e.platform === 'android'
            )}
          />
        )}
        {tab == 'iOS' && (
          <AppList
            downloadInfo={filteredContent.filter((e) => e.platform === 'ios')}
          />
        )}
        {tab == 'Windows' && (
          <AppList
            downloadInfo={filteredContent.filter(
              (e) => e.platform === 'windows'
            )}
          />
        )}
      </Space>
    </div>
  );
}

export default App;

import React from 'react';

import { OsType } from '../dropdowns/os/osDropdown';
import { Affix, Button, List, Popover, QRCode, Typography } from 'antd';
import { AppInfo } from '../App';

const { Link } = Typography;

export default function AppList({ downloadInfo }: { downloadInfo: AppInfo[] }) {
  console.log(downloadInfo);
  return (
    <>
      <List
        style={{
          overflow: 'auto',
          maxHeight: '600px'
        }}
        bordered
        dataSource={downloadInfo
          .sort((a, b) => a.created_at.localeCompare(b.created_at))
          .reverse()}
        renderItem={(item) => (
          <List.Item>
            <Popover
              content={() => (
                <QRCode
                  value={item.downloadUrl}
                  size={500}
                  icon={'https://dl2.sgola.cc/partying_icon.png'}
                />
              )}
              trigger="hover"
            >
              <Link href={item.downloadUrl}>
                <span>{`Branch: ${
                  item.branch === '?'
                    ? '<unknown>'
                    : item.branch.replaceAll('_', '/')
                }`}</span>
                <br></br>
                <span>{`Type: ${item.build_type}`}</span>
                <br></br>
                <span>{`Build number: ${item.build_number}`}</span>
                <br></br>
                {item.runNumber !== '' && (
                  <span>{`Github Run Number: ${item.runNumber}`}</span>
                )}
              </Link>
            </Popover>
          </List.Item>
        )}
      ></List>
    </>
  );
}
